/* You can add global styles to this file, and also import other style files */

@import "./app/_themes/variables.scss";

a {
    cursor: pointer;
}

.sk-chase {
    width: 40px;
    height: 40px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
}

.btn-link {
    text-decoration: none !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background: #00325b3b !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background: var(--color-primary);
}

.contdown {
    span {
        font-weight: 500 !important;
        font-size: 1.1rem;
        color: var(--color-primary);
    }
}
.sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
    content: "";
    display: block;
    width: 25%;
    height: 25%;
    background-color: var(--color-primary);
    border-radius: 100%;
    animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sport-content-conter {
    font-size: 11px !important;
}

.sk-chase-dot:nth-child(1) {
    animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2) {
    animation-delay: -1s;
}

.sk-chase-dot:nth-child(3) {
    animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4) {
    animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5) {
    animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6) {
    animation-delay: -0.6s;
}

.sk-chase-dot:nth-child(1):before {
    animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2):before {
    animation-delay: -1s;
}

.sk-chase-dot:nth-child(3):before {
    animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4):before {
    animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5):before {
    animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6):before {
    animation-delay: -0.6s;
}

@keyframes sk-chase {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-chase-dot {
    80% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-chase-dot-before {
    50% {
        transform: scale(0.4);
    }

    100% {
        transform: scale(1);
    }

    0% {
        transform: scale(1);
    }
}

.fadein {
    animation: fadeIn 1.5s forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.ngx-pagination .current {
    background: var(--color-primary) !important;
}

.mat-select-panel {
    z-index: 9999 !important;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
    z-index: 99999 !important;
}

.file-drop-area {
    border: 1px dashed #7c7db3;
    border-radius: 3px;
    position: relative;
    max-width: 100%;
    margin-top: 5px;
    padding: 26px 20px 30px;
    transition: 0.2s;
}

.file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
}

.file-msg {
    display: inline-block;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #5b5bff;
}

.center:not(th, td) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.clickable {
    cursor: pointer;
}

.mat-tab-body-content {
    overflow: hidden !important;
}

.mat-form-field.mat-focused {
    .mat-form-field-required-marker {
        color: #757575 !important;
    }
}

.mat-form-field.mat-form-field-invalid {
    .mat-form-field-required-marker {
        color: #757575 !important;
    }
}

.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline-thick {
    color: #757575 !important;
    font-weight: 600 !important;
}

.mat-form-field.mat-form-field-invalid {
    .mat-form-field-label {
        color: #757575 !important;
    }

    // .mat-form-field-outline-thick {
    //     border-color: green !important;
    //     color: green;
    // }
}
